import i18n from "i18next";

export const profileTranslations = () => {
  i18n.addResourceBundle('en', 'translations',
    {
      "First Name": "First Name",
      "Last Name": "Last Name",
      "Password": "Password",
      "Phone Number": "Phone Number",
      "Address": "Address",
      "Cuisine Preference": "Cuisine Preference", "Select all of the following cuisines that you enjoy": "Select all of the following cuisines that you enjoy",
      "Please select your dietary restrictions": "Please select your dietary restrictions",
      "Save Profile": "Save Profile",
      "Dietary Restrictions": "Dietary Restrictions",

      "Signup": "Signup",
      "Your Work Email Address": "Your Work Email Address",
      "After you signup, your company admin will be notified and requested to approve your signup.": "After you signup, your company admin will be notified and requested to approve your signup.",

      "Billing": "Billing",
      "Billing Information": "Billing Information",
      "Pay by Credit Card": "Pay by Credit Card",
      "Send us an Invoice": "Send us an Invoice",
      "Name on Invoice": "Name on Invoice",
      "City": "City",
      "State": "State",
      "Zip": "Zip",
      "Invoice Approver Name": "Invoice Approver Name",
      "Invoice Approver Email": "Invoice Approver Email",
      "Save": "Save",
      "Email Address": "Email Address",

      "Notifications": "Notifications",
      "Receive emails to rate our food & service": "Receive emails to rate our food & service",
      "Receive Reminder Emails One Hour Before Cutoff for": "Receive Reminder Emails One Hour Before Cutoff for",
      "Receive Reminder Emails 24 Hours Before Cutoff for": "Receive Reminder Emails 24 Hours Before Cutoff for",
      "Receive text message when food is delivered": "Receive text message when food is delivered",
      "Receive Text messages when food": "Receive Text messages when food",
      "Receive Notification Email When New Menu is Ready": "Receive Notification Email When New Menu is Ready",
      "© Copyright": " © Copyright ",
      "Chowmill": " Chowmill ",
      "Billing information is already saved.": "Billing information is already saved.",
      "Update card.": "Update card."
    });
  i18n.addResourceBundle('ur', 'translations',
    {
      "First Name": "پہلا نام",
      "Last Name": "آخری نام",
      "Password": "پاس ورڈ",
      "Phone Number": "فون نمبر",
      "Address": "پتہ",
      "Cuisine Preference": "کھانے کی ترجیح",
      "Select all of the following cuisines that you enjoy": "آپ مندرجہ ذیل تمام پکوان منتخب کریں",
      "Please select your dietary restrictions": "برائے مہربانی اپنی غذائی پابندی کا انتخاب کریں",
      "Save Profile": "پروفائل محفوظ کریں",

      "Signup": "سائن اپ",
      "Your Work Email Address": "آپ کے کام کا ای میل ایڈریس",
      "After you signup, your company admin will be notified and requested to approve your signup.": "آپ کے سائن اپ کرنے کے بعد ، آپ کے کمپنی کے منتظم کو مطلع کیا جائے گا اور آپ کے سائن اپ کو منظور کرنے کی درخواست کی جائے گی",

      "Billing": "بلنگ",
      "Billing Information": "بلنگ کی معلومات",
      "Pay by Credit Card": "کریڈٹ کارڈ کے ذریعے ادائیگی",
      "Send us an Invoice": "ہمیں ایک انوائس بھیجیں",
      "Name on Invoice": "انوائس پر نام",
      "City": "شہر",
      "State": "حالت",
      "Zip": "زپ",
      "Invoice Approver Name": "انوائس منظور نام",
      "Invoice Approver Email": "انوائس منظور شدہ ای میل",
      "Save": "محفوظ کریں",

      "Notifications": "اطلاعات",
      "Receive emails to rate our food & service": "ہمارے کھانے اور خدمات کی درجہ بندی کرنے کیلئے ای میل موصول کریں",
      "Receive Reminder Emails One Hour Before Cutoff for": "کٹ آف سے ایک گھنٹہ پہلے یاد دہانی ای میل موصول کریں",
      "Receive Reminder Emails 24 Hours Before Cutoff for": "کٹ آف سے 24 گھنٹے پہلے یاد دہانی ای میل موصول کریں",
      "Receive text message when food is delivered": "کھانا پہنچانے پر ٹیکسٹ میسج وصول کریں",
      "Receive Text messages when food": "جب کھانا ہو تو ٹیکسٹ پیغامات وصول کریں",
      "Receive Notification Email When New Menu is Ready": "نیا مینو تیار ہونے پر اطلاعاتی ای میل موصول کریں",
      "Dietary Restrictions": "غذائی پابندیاں",
      "Email Address": "ای میل اڈریس",
      "© Copyright": " © کاپی رائٹ ",
      "Chowmill": " چومیل ",
      "Billing information is already saved.": "بلنگ کی معلومات پہلے ہی محفوظ ہوگئی ہے۔",
      "Update card.": " اپ ڈیٹ کریں۔ "
    });
};