// // Packages
// import React, { Component, Suspense } from 'react';
// // import 'core-js';
// import dayjs from 'dayjs';
// import utc from 'dayjs/plugin/utc';
// import { hotjar } from 'react-hotjar';
// import queryString from 'query-string';
// import { Provider } from 'react-redux';
// import duration from 'dayjs/plugin/duration';
// import timezone from 'dayjs/plugin/timezone';
// import relativeTime from 'dayjs/plugin/relativeTime';
// import advancedFormat from 'dayjs/plugin/advancedFormat';
// import customParseFormat from 'dayjs/plugin/customParseFormat';
// import { withTranslation, WithTranslation } from 'react-i18next';
// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// import { PersistGate } from 'redux-persist/integration/react'
// import { persistStore } from 'redux-persist';
// // React Components
// // import Account from './Pages/AccountDetail/index';
// import AdminLogin from './Pages/Login/AdminLogin';
// import AdminRoute from './Common/Routes/AdminRoute';
// // import AllDeliveriesDashboard from './Pages/Dashboard/AllDeliveriesDashboard';
// import AppCues from './Common/AppCues';
// import ApprovedMeetingMenuDetail from './Pages/Menu/ApprovedMeetingMenuDetail/ApprovedMeetingMenuDetail';
// // import CompanyOrders from './Pages/OrderHistory/CompanyOrders';
// // import ConfirmUserInvites from './Pages/Invites/ConfirmUserInvites/ConfirmUserInvites';
// // import EditProfile from './Pages/Profile/EditProfile/EditProfile';
// import ErrorBoundary from './Common/ErrorBoundary';
// // import ForgotPassword from './Pages/ForgotPassword/ForgotPassword';
// import GenericShareMeeting from './Pages/GenericShareMeeting/GenericShareMeeting';
// // import InviteForProfileSignup from './Pages/InviteForProfileSignup/InviteForProfileSignup';
// import InviteUsers from './Pages/Invites/InviteUsers/InviteUsers';
// import Login from './Pages/Login/Login';
// // import Logout from './Pages/Logout/Logout';
// import NotFoundRoute from './Common/Routes/NotFoundRoute';
// import PrivateRoute from './Common/Routes/PrivateRoute';
// // import Reports from './Pages/Reports/MetaReports';
// // import RequestUser from './Pages/Signup/RequestUser';
// // import SignupForm from './Pages/Signup/SignupForm';
// import SignUpWidget from './Pages/Signup/SignUpWidget';
// // import ResetPassword from './Pages/ResetPassword/ResetPassword';
// import SharedMeetingMenuDetail from './Pages/Menu/SharedMeetingMenuDetail/SharedMeetingMenuDetail.tsx';
// // import SignUpProfile from './Pages/Profile/SignupProfile/SignUpProfile';
// // import UnlockUserAccount from './Pages/UnlockAccount/UnlockAccount';
// // import UserList from './Pages/Users/UserList';
// // import UserSignupRequestConfirmation from './Pages/UserSignupRequestConfirmation/UserSignupRequestConfirmation';
// // import ShortURL from './Pages/ShortUrl/ShortURL';
// // Redux Actions
// import { setCurrentUser } from './Actions/authActions';
// import { getFutureDelivery } from './Actions/userActions';
// import { setShowMenuTabLoading } from './Actions/menuActions';
// import { SET_MENU_TAB_MEETING, TRANSLATE_I18N } from './Actions/types';
// // Utitlities
// import store from './store';
// import { getloggedInUser } from './Utilities/getloggedInUser';
// // CSS
// import 'animate.css/animate.min.css';
// import 'react-notifications-component/dist/theme.css';
// import 'react-day-picker/dist/style.css';
// import "react-loading-skeleton/dist/skeleton.css";
// import PublicMarketPlace from './Pages/Menu/MarketplacePreview/PublicMarketPlaceWrapper';
// import GetAddressForMarketPlace from './Pages/Menu/MarketplacePreview/GetAddressForMarketPlace';
// // import isEmpty from './Utilities/is_empty';
// // Lazy Imports
// const ForgotPassword: any = React.lazy(() => import('./Pages/ForgotPassword/ForgotPassword'));
// const ResetPassword: any = React.lazy(() => import('./Pages/ResetPassword/ResetPassword'));
// const Logout: any = React.lazy(() => import('./Pages/Logout/Logout'));
// const UnlockUserAccount: any = React.lazy(() => import('./Pages/UnlockAccount/UnlockAccount'));
// const InviteForProfileSignup: any = React.lazy(() => import('./Pages/InviteForProfileSignup/InviteForProfileSignup'));
// const UserSignupRequestConfirmation: any = React.lazy(() => import('./Pages/UserSignupRequestConfirmation/UserSignupRequestConfirmation'));
// // const NotFoundRoute: any = React.lazy(() => import('./Common/Routes/NotFoundRoute'));
// const AllDeliveriesDashboard: any = React.lazy(() => import('./Pages/Dashboard/AllDeliveriesDashboard'));
// const UserList: any = React.lazy(() => import('./Pages/Users/UserList'));
// const CompanyOrders: any = React.lazy(() => import('./Pages/OrderHistory/CompanyOrders'));
// const Reports: any = React.lazy(() => import('./Pages/Reports/MetaReports'));
// const ShortURL: any = React.lazy(() => import('./Pages/ShortUrl/ShortURL'));
// const Account: any = React.lazy(() => import('./Pages/AccountDetail/index'));
// const ConfirmUserInvites: any = React.lazy(() => import('./Pages/Invites/ConfirmUserInvites/ConfirmUserInvites'));
// const EditProfile: any = React.lazy(() => import('./Pages/Profile/EditProfile/EditProfile'));
// const SignUpProfile: any = React.lazy(() => import('./Pages/Profile/SignupProfile/SignUpProfile'));
// const EmailVerificaionExternalLink: any = React.lazy(() => import('./Pages/Invites/EmailVerificationInvite/EmailVerificationExternalLink'))

// // Check for headers
// if (localStorage.headers) {
//   store.dispatch(setCurrentUser(getloggedInUser().loggedInUser, localStorage.headers));
// }
// class App extends Component<WithTranslation, {}> {

//   constructor(props: WithTranslation) {
//     super(props);
//     dayjs.extend(customParseFormat);
//     dayjs.extend(duration);
//     dayjs.extend(relativeTime);
//     dayjs.extend(timezone);
//     dayjs.extend(utc);
//     dayjs.extend(advancedFormat);
//   }

//   getMenuTabDelivery = async () => {
//     store.dispatch(setShowMenuTabLoading());
//     const { data: { meeting = null } = {} } = await getFutureDelivery(true) || {};
//     store.dispatch({
//       type: SET_MENU_TAB_MEETING,
//       payload: meeting
//     });
//   }

//   componentDidMount() {
//     const { menu: { menu_tab_meeting_loading } } = store.getState();
//     const searchParams = queryString.parse(window.location.search);
//     // Store Info to decide weather Show View All Items Popup or NOT on Menu Page.
//     if (searchParams?.order_details) {
//       localStorage.setItem('order_details', `${searchParams?.order_details}`);
//     }
//     //Weather to show Menu Tab or not
//     if (!menu_tab_meeting_loading && localStorage.headers) { // Only call this API if it is not called earlier from any child Component
//       this.getMenuTabDelivery();
//     }
//     if (process.env.REACT_APP_ENVIRONMENT === 'production') {
//       hotjar.initialize(1942393, 6);
//       console.log('hotjar env testing', process.env.REACT_APP_ENVIRONMENT)
//     }
//     this.props.i18n.changeLanguage(window.navigator.language);
//     store.dispatch({
//       type: TRANSLATE_I18N,
//       payload: this.props
//     });
//     if (process.env.REACT_APP_ENVIRONMENT === 'production') {
//       let globalWindow: any = window;
//       globalWindow.dataLayer = globalWindow.dataLayer || [];
//       function gtag(..._args: unknown[]) { globalWindow.dataLayer.push(arguments); }
//       gtag('js', new Date());
//       gtag('config', 'GTM-WHTW9CG');
//       // ReactGA.initialize('GTM-WHTW9CG');
//       // ReactGA.pageview(window.location.pathname + window.location.search);
//     }
//   }

//   render() {
//     let persistor = persistStore(store);
//     return (
//       <Provider store={store}>
//         <PersistGate loading={null} persistor={persistor}>
//         <Suspense>
//           <Router>
//             <ErrorBoundary i18n={this.props.i18n}>
//               <AppCues />
//               <Switch>
//                 <Route exact path={`/get-address`} component={GetAddressForMarketPlace} />
//                 <Route exact path={`/home/:date?`} component={PublicMarketPlace} />
                
//                 {localStorage.getItem('headers') ?
//                   <PrivateRoute exact path={[`/menu/:date?/:meetingSlug?`]} component={ApprovedMeetingMenuDetail} />
//                   :
//                   <Route exact path={`/share-meeting/:date/:token`} component={SharedMeetingMenuDetail} />
//                 }
//                 <Route exact path={`/user/signup`} component={SignUpWidget} />
//                 {/* <Route exact path={`/user/signup`} component={RequestUser} /> */}
//                 <PrivateRoute exact path={`/signup`} component={SignUpProfile} />
//                 <PrivateRoute exact path={`/dashboard`} component={AllDeliveriesDashboard} />
//                 <Route exact path={`/meeting/:meetingSlug/:token`} component={GenericShareMeeting} />
//                 <Route exact path={`/admin-login/:token`} component={AdminLogin} />
//                 {/* <Route exact path={`/`} component={Login} /> */}
//                 {/* Default routes changes from login to marketplace */}
//                 {/* <Route exact path={`/`} component={isEmpty(localStorage.getItem('address')) ? Login : PublicMarketPlace} /> */}
//                 <Route exact path={`/signin`} component={Login} />
//                 <Route exact path={`/`} component={PublicMarketPlace} />
//                 <Route exact path={`/forgot_password`} component={ForgotPassword} />
//                 {/* option 1 */}
//                 {/* <Route exact path={`/forgot_password`} component={LazyLoader(ForgotPassword)}/> */}
//                 {/* // Option 2 */}
//                 {/* // Not working
//                 <LazyRoute exact path={`/forgot_password`} component={ForgotPassword} /> */}
//                 <Route exact path={`/reset_password`} component={ResetPassword} />
//                 <Route exact path={`/user/verify-email/:token`} component={EmailVerificaionExternalLink} />
//                 <Route exact path={`/confirm-signup`} component={UserSignupRequestConfirmation} />
//                 <PrivateRoute exact path={`/profile/:type?`} component={EditProfile} />
//                 <Route exact path={`/user/invite/invite_code/:inviteToken`} component={InviteForProfileSignup} />
//                 <AdminRoute exact path={`/invite-user`} component={InviteUsers} />
//                 <AdminRoute exact path={`/confirm-invite`} component={ConfirmUserInvites} />
//                 <AdminRoute exact path={`/users`} component={UserList} />
//                 <PrivateRoute exact path={`/order-history/:date?/:endDate?`} component={CompanyOrders} />
//                 <AdminRoute exact path={`/reports`} component={Reports} />
//                 <AdminRoute exact path={`/account/:company_id?`} component={Account} />
//                 <Route exact path='/unlock-account/:token' component={UnlockUserAccount} />
//                 <Route exact path={`/logout`} component={Logout} />
//                 <PrivateRoute exact path='/:token' component={ShortURL} />
//                 <Route path={`*`} exact component={NotFoundRoute} />
//               </Switch>
//             </ErrorBoundary>
//           </Router>
//         </Suspense>
//         </PersistGate>
//       </Provider>
//     );
//   }
// }
// export default withTranslation()(App);

// Packages
import React, { Suspense, useEffect } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { hotjar } from 'react-hotjar';
import queryString from 'query-string';
import { Provider } from 'react-redux';
import duration from 'dayjs/plugin/duration';
import timezone from 'dayjs/plugin/timezone';
import relativeTime from 'dayjs/plugin/relativeTime';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist';
// React Components
import AdminLogin from './Pages/Login/AdminLogin';
import AdminRoute from './Common/Routes/AdminRoute';
import AppCues from './Common/AppCues';
import ApprovedMeetingMenuDetail from './Pages/Menu/ApprovedMeetingMenuDetail/ApprovedMeetingMenuDetail';
import ErrorBoundary from './Common/ErrorBoundary';
import GenericShareMeeting from './Pages/GenericShareMeeting/GenericShareMeeting';
import InviteUsers from './Pages/Invites/InviteUsers/InviteUsers';
import Login from './Pages/Login/Login';
import NotFoundRoute from './Common/Routes/NotFoundRoute';
import PrivateRoute from './Common/Routes/PrivateRoute';
import SignUpWidget from './Pages/Signup/SignUpWidget';
import SharedMeetingMenuDetail from './Pages/Menu/SharedMeetingMenuDetail/SharedMeetingMenuDetail.tsx';
// Redux Actions
import { setCurrentUser } from './Actions/authActions';
import { getFutureDelivery } from './Actions/userActions';
import { setShowMenuTabLoading } from './Actions/menuActions';
import { SET_MENU_TAB_MEETING, TRANSLATE_I18N } from './Actions/types';
// Utitlities
import store from './store';
import { getloggedInUser } from './Utilities/getloggedInUser';
// CSS
import 'animate.css/animate.min.css';
import 'react-notifications-component/dist/theme.css';
import 'react-day-picker/dist/style.css';
import "react-loading-skeleton/dist/skeleton.css";
// import PublicMarketPlace from './Pages/Menu/MarketplacePreview/PublicMarketPlaceWrapper';
import GetAddressForMarketPlace from './Pages/Menu/MarketplacePreview/GetAddressForMarketPlace';
import PublicRestaurantMenu from './Pages/Menu/MarketplacePreview/PublicRestaurantMenu';
import isEmpty from './Utilities/is_empty';
import getPlaceDetails from './Utilities/locationApi';
import PublicLocationMarketplace from './Pages/Menu/MarketplacePreview/PublicLocationMarketplace';
import ReferralModal from './Common/Popups/ReferralModal';
// Lazy Imports
const ForgotPassword: any = React.lazy(() => import('./Pages/ForgotPassword/ForgotPassword'));
// const PublicLocationMarketplacePage: any = React.lazy(() => import('./Pages/Menu/MarketplacePreview/PublicLocationMarketplace'))
const ResetPassword: any = React.lazy(() => import('./Pages/ResetPassword/ResetPassword'));
const Logout: any = React.lazy(() => import('./Pages/Logout/Logout'));
const UnlockUserAccount: any = React.lazy(() => import('./Pages/UnlockAccount/UnlockAccount'));
const InviteForProfileSignup: any = React.lazy(() => import('./Pages/InviteForProfileSignup/InviteForProfileSignup'));
const UserSignupRequestConfirmation: any = React.lazy(() => import('./Pages/UserSignupRequestConfirmation/UserSignupRequestConfirmation'));
// const AllDeliveriesDashboard: any = React.lazy(() => import('./Pages/Dashboard/AllDeliveriesDashboard'));
const UserList: any = React.lazy(() => import('./Pages/Users/UserList'));
const CompanyOrders: any = React.lazy(() => import('./Pages/OrderHistory/CompanyOrders'));
const Reports: any = React.lazy(() => import('./Pages/Reports/MetaReports'));
const ShortURL: any = React.lazy(() => import('./Pages/ShortUrl/ShortURL'));
const Account: any = React.lazy(() => import('./Pages/AccountDetail/index'));
const ConfirmUserInvites: any = React.lazy(() => import('./Pages/Invites/ConfirmUserInvites/ConfirmUserInvites'));
const EditProfile: any = React.lazy(() => import('./Pages/Profile/EditProfile/EditProfile'));
const SignUpProfile: any = React.lazy(() => import('./Pages/Profile/SignupProfile/SignUpProfile'));
const EmailVerificaionExternalLink: any = React.lazy(() => import('./Pages/Invites/EmailVerificationInvite/EmailVerificationExternalLink'))
const SlackIntegrationComponent: any = React.lazy(() => import('./Pages/Profile/Common/SlackIntegration'))

// Check for headers
if (localStorage.headers) {
  store.dispatch(setCurrentUser(getloggedInUser().loggedInUser, localStorage.headers));
}

const App = () => {
  dayjs.extend(customParseFormat);
  dayjs.extend(duration);
  dayjs.extend(relativeTime);
  dayjs.extend(timezone);
  dayjs.extend(utc);
  dayjs.extend(advancedFormat);
  dayjs.tz.setDefault(getloggedInUser().loggedInUser?.time_zone)

  const { i18n } = useTranslation();

  const url = window.location.pathname?.split('/')
  const [, home, date, address_id] = url
  const { enable_referral_factory } = JSON.parse(localStorage.getItem('company') || '{}');

  localStorage.setItem('IsValidAddress', JSON.stringify(false))
  const fetchLocationData = async (placeId: string) => {
    const data = await getPlaceDetails(placeId);
    saveAddress(data);
  };
  const saveAddress = (address: any) => {
    const user = JSON.parse(localStorage.getItem('user') || '{}')
    if (isEmpty(user?.phone_number)) {
      if (!isEmpty(address?.latitude)) {
        localStorage.setItem("address", JSON.stringify(address));
      }
    }
  };

  const fetchDataOrGeolocation = async (placeId: any) => {
    try {
      let locationData = null
      // Replace the following with your actual API call or geolocation logic
      const googlePlacesData = await getPlaceDetails(placeId);

      if (isEmpty(googlePlacesData)) {
        const newReqForGettingData = await getPlaceDetails(placeId)
        locationData = newReqForGettingData
        localStorage.setItem("address", JSON.stringify(locationData));

      }
    } catch (error) {
      console.error('Error fetching data or geolocation:', error);
    }
  };

  useEffect(() => {
    const { menu: { menu_tab_meeting_loading } } = store.getState();
    const searchParams = queryString.parse(window.location.search);
    // Store Info to decide weather Show View All Items Popup or NOT on Menu Page.
    if (searchParams?.order_details) {
      localStorage.setItem('order_details', `${searchParams?.order_details}`);
    }
    //Weather to show Menu Tab or not
    if (!menu_tab_meeting_loading && localStorage.headers) { // Only call this API if it is not called earlier from any child Component
      getMenuTabDelivery();
    }

    if (!isEmpty(address_id)) {
      const addressPlaceId = `${address_id}` ?? ''

      if (!isEmpty(home) && home === 'home' && !isEmpty(date)) {
        // Redirect from the OLD URL to new one
        window.location.replace(`/get-address?placeId=${address_id}`);
      }
      fetchDataOrGeolocation(addressPlaceId)
      if (addressPlaceId) {
        // Fetching location data using the utility function
        fetchLocationData(addressPlaceId);
      } else {
        console.error('Place ID not found in the URL');
      }
    }
    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      hotjar.initialize(1942393, 6);
      console.log('hotjar env testing', process.env.REACT_APP_ENVIRONMENT)
    }
    i18n && i18n?.t(window?.navigator?.language);
    store.dispatch({
      type: TRANSLATE_I18N,
      payload: i18n
    });
    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      let globalWindow: any = window;
      globalWindow.dataLayer = globalWindow.dataLayer || [];
      function gtag(..._args: unknown[]) { globalWindow.dataLayer.push(arguments); }
      gtag('js', new Date());
      gtag('config', 'GTM-WHTW9CG');
      // ReactGA.initialize('GTM-WHTW9CG');
      // ReactGA.pageview(window.location.pathname + window.location.search);
    }

    // eslint-disable-next-line
  }, [i18n]);

  useEffect(() => {
    // Check if the Google Maps API has been loaded
    if (window.google && window.google.maps) {
      // setApiLoaded(true);
      console.log('google api loaded sucessfully')
    } else {
      // Load the Google Maps API dynamically
      const script = document.createElement('script');
      script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyDS0wbOsjYPi6CaKvbs13USS5CUOc2D91c&libraries=places&callback=googleAPI";
      script.async = true;
      script.defer = true;
      script.onload = () => console.log('Error while loading google api');

      document.head.appendChild(script);
    }
  }, []);


  useEffect(() => {
    // HubSpot Integration Code
    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = '//js-na1.hs-scripts.com/20301269.js';
      script.async = true;
      script.defer = true;
      script.id = 'hs-script-loader'; 
  
      document.head.appendChild(script);
  
      return () => {
        const scriptElement = document.getElementById('hs-script-loader');
        if (scriptElement) {
          document.head.removeChild(scriptElement);
        }
      };
    }
  }, []);
  

  const getMenuTabDelivery = async () => {
    store.dispatch(setShowMenuTabLoading());
    const { data: { meeting = null } = {} } = await getFutureDelivery(true) || {};
    store.dispatch({
      type: SET_MENU_TAB_MEETING,
      payload: meeting
    });
  }

  let persistor = persistStore(store);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Suspense>
          <Router>
            <ErrorBoundary i18n={i18n}>
              <AppCues />
              {enable_referral_factory ? <ReferralModal /> : <></>}
              <Switch>
                <Route exact path={`/get-address`} component={GetAddressForMarketPlace} />
                {/* <Route exact path={`/home/:date?/:address_id?/:rest_id?`} component={PublicMarketPlace} /> */}
                <Route exact path={`/restaurant/:date/:address_id/:restaurant_name/:rest_id`} component={PublicRestaurantMenu} />
                {localStorage.getItem('headers') ?
                  <PrivateRoute exact path={[`/menu/:date?/:meetingSlug?`]} component={ApprovedMeetingMenuDetail} />
                  :
                  <Route exact path={`/share-meeting/:date/:token`} component={SharedMeetingMenuDetail} />
                }
                <Route exact path={`/user/signup`} component={SignUpWidget} />
                <PrivateRoute exact path={`/signup`} component={SignUpProfile} />
                {/* <PrivateRoute exact path={`/dashboard`} component={AllDeliveriesDashboard} /> */}
                <Route exact path={`/meeting/:meetingSlug/:token`} component={GenericShareMeeting} />
                <Route exact path={`/admin-login/:token`} component={AdminLogin} />
                <Route exact path={`/signin`} component={Login} />
                {/* <Route exact path={`/address/:dynamicAddressString`} component={PublicLocationMarketplacePage} /> */}
                <Route exact path={`/`} component={PublicLocationMarketplace} />
                {/* <Route exact path={`/`} component={PublicMarketPlace} /> */}
                <Route exact path={`/forgot_password`} component={ForgotPassword} />
                <Route exact path={`/reset_password`} component={ResetPassword} />
                <Route exact path={`/user/verify-email/:token`} component={EmailVerificaionExternalLink} />
                <Route exact path={`/confirm-signup`} component={UserSignupRequestConfirmation} />
                <PrivateRoute exact path={`/profile/:type?`} component={EditProfile} />
                <Route exact path={`/user/invite/invite_code/:inviteToken`} component={InviteForProfileSignup} />
                <AdminRoute exact path={`/invite-user`} component={InviteUsers} />
                <AdminRoute exact path={`/confirm-invite`} component={ConfirmUserInvites} />
                <AdminRoute exact path={`/users`} component={UserList} />
                <PrivateRoute exact path={`/order-history/:date?/:endDate?`} component={CompanyOrders} />
                <AdminRoute exact path={`/reports`} component={Reports} />
                <AdminRoute exact path={`/account/:company_id?`} component={Account} />
                <Route exact path='/unlock-account/:token' component={UnlockUserAccount} />
                <Route exact path={`/logout`} component={Logout} />
                <Route exact path={`/oath/slack`} component={SlackIntegrationComponent} />
                <PrivateRoute exact path='/:token' component={ShortURL} />
                <Route path={`*`} exact component={NotFoundRoute} />
              </Switch>
            </ErrorBoundary>
          </Router>
        </Suspense>
      </PersistGate>
    </Provider>
  );
}

export default App